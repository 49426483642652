import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import useSiteMetadata from "../components/SiteMetadata";
import { globalHistory as history } from "@reach/router";

export const EducatorResourceTemplate = ({
  featuredImage,
  featuredImageAlt,
  content,
  contentComponent,
  dateLastUpdated,
  datePosted,
  author,
  description,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            {!!featuredImage && (
              <figure className="image">
                <img
                  src={
                    !!featuredImage.childImageSharp
                      ? featuredImage.childImageSharp.fluid.src
                      : featuredImage
                  }
                  alt={featuredImageAlt}
                />
              </figure>
            )}
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            {/*<div className="subtitle is-size-5">{description}</div>*/}
            <p className="is-size-6">
              <strong>Posted:</strong> {datePosted} | <strong>Updated:</strong>{" "}
              {dateLastUpdated}
              <br />
              <strong>Created by:</strong> {author}
            </p>
            <PostContent content={content} />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <div className="is-size-4">Tags</div>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

EducatorResourceTemplate.propTypes = {
  featuredImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  featuredImageAlt: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  dateLastUpdated: PropTypes.string,
  datePosted: PropTypes.string,
  author: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const EducatorResource = ({ data }) => {
  const { siteUrl } = useSiteMetadata();
  const { location } = history;

  let currentOrigin = siteUrl;
  try {
    if (!!location.origin) {
      currentOrigin = location.origin;
    }
  } catch (e) {}

  const { markdownRemark: post } = data;
  return (
    <Layout>
      <EducatorResourceTemplate
        featuredImage={post.frontmatter.featuredImage}
        featuredImageAlt={post.frontmatter.featuredImageAlt}
        dateLastUpdated={post.frontmatter.dateLastUpdated}
        datePosted={post.frontmatter.datePosted}
        author={post.frontmatter.author}
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Educator Resource">
            <title>{post.frontmatter.title}</title>
            <meta name="description" content={post.frontmatter.description} />
            <meta
              property="og:title"
              content={`${post.frontmatter.title} | Educator Resource`}
            />
            <meta
              property="og:description"
              content={post.frontmatter.description}
            />
            {!!post.frontmatter.featuredImage && (
              <meta
                property="og:image"
                content={`${currentOrigin}${
                  !!post.frontmatter.featuredImage.childImageSharp
                    ? post.frontmatter.featuredImage.childImageSharp.fluid.src
                    : post.frontmatter.featuredImage
                }`}
              />
            )}
            <meta
              name="twitter:title"
              content={`${post.frontmatter.title} | Educator Resource`}
            />
            <meta
              name="twitter:description"
              content={post.frontmatter.description}
            />
            {!!post.frontmatter.featuredImage && (
              <meta
                name="twitter:image"
                content={`${currentOrigin}${
                  !!post.frontmatter.featuredImage.childImageSharp
                    ? post.frontmatter.featuredImage.childImageSharp.fluid.src
                    : post.frontmatter.featuredImage
                }`}
              />
            )}
            {!!post.frontmatter.featuredImageAlt && (
              <meta
                name="twitter:image:alt"
                content={post.frontmatter.featuredImageAlt}
              />
            )}
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

EducatorResource.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default EducatorResource;

export const pageQuery = graphql`
  query EducatorResourceByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        datePosted(formatString: "MMMM DD, YYYY")
        dateLastUpdated(formatString: "MMMM DD, YYYY")
        author
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImageAlt
        description
        tags
      }
    }
  }
`;
